// Modules
import Link from "next/link";
import { withRouter } from "next/router";
import React, { Children } from "react";

const ActiveLink = withRouter(({ router, children, ...props }) => (
  <Link {...props} legacyBehavior>
    {React.cloneElement(Children.only(children), {
      className: router.pathname === props.href ? props.activeClassName : null,
    })}
  </Link>
));

export default ActiveLink;
