// Modules
import React from "react";
import { useRouter } from "next/router";
import ActiveLink from "../ActiveLink";

// Styles
import styles from "./PCHeader.module.scss";

// Components
import ButtonContainer from "../../buttonContainer/ButtonContainer";

// Assets
import {
  headerWhiteLogoIcon,
  headerGreyLogoIcon,
} from "../../../utils/iconProvider";

const PCHeader = ({
  isTransparent,
  boxShadow,
  showHeader,
  isAnnouncement,
  url,
}) => {
  const router = useRouter();

  const getCTA = () => {
    const wrapStyle = {
      backgroundColor: `${isTransparent ? "#f5a623" : "#000000"}`,
      color: `${isTransparent ? "#000000" : "#ffffff"}`,
      fontWeight: 600,
    };

    switch (router.pathname) {
      case "/about":
        return (
          <ButtonContainer
            buttonText={"Join Us"}
            url={"http://join.fampay.in/"}
            wrapStyle={wrapStyle}
            activeStatus
          />
        );

      case "/careers":
        return (
          <ButtonContainer
            buttonText={"View openings"}
            path="/jobs"
            wrapStyle={wrapStyle}
            activeStatus
          />
        );

      default:
        return (
          <ButtonContainer
            buttonText={"Download"}
            url={url}
            wrapStyle={wrapStyle}
            activeStatus
          />
        );
    }
  };

  return (
    <div
      className={`${styles.header_outer_container}
       ${isTransparent ? " " : `${styles.white_background} `}
       ${boxShadow && !isTransparent ? `${styles.box_shadow}` : " "}
      `}
      style={{
        top: `${showHeader ? (isAnnouncement ? "60px" : "0") : "-12rem"}`,
      }}
    >
      <div className={`${styles.header_container}`}>
        <ActiveLink href={"/"}>
          <a>
            <img
              className={styles.logo}
              alt="FamPay"
              src={isTransparent ? headerWhiteLogoIcon : headerGreyLogoIcon}
            />
          </a>
        </ActiveLink>
        <div className={`${styles.navlinks_container} subparagraph_text`}>
          <div className={styles.navlink}>
            <ActiveLink href={"/about"} activeClassName={styles.navlink_active}>
              <a>About</a>
            </ActiveLink>
          </div>
          <div className={styles.navlink}>
            <ActiveLink
              href="https://blog.famapp.in/"
              activeClassName={styles.navlink_active}
              target="_blank"
            >
              <a>Blog</a>
            </ActiveLink>
          </div>
          <div>{getCTA()}</div>
        </div>
      </div>
    </div>
  );
};
export default PCHeader;
