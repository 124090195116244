const NEW_BASE = "/assets/images/icons";

// Common Icons
export const singleGreyArrowIcon = `${NEW_BASE}/common/singleGreyArrowIcon.svg`;
export const instagramColorIcon = `${NEW_BASE}/common/instagramColorIcon.svg`;
export const playstoreColorIcon = `${NEW_BASE}/common/playstoreColorIcon.svg`;
export const appstoreColorIcon = `${NEW_BASE}/common/appstoreColorIcon.svg`;
export const whatsappColorIcon = `${NEW_BASE}/common/whatsappColorIcon.svg`;
export const twitterColorIcon = `${NEW_BASE}/common/twitterColorIcon.svg`;
export const famcoinIcon = `${NEW_BASE}/common/famcoinIcon.svg`;
export const linkedinColorIcon = `${NEW_BASE}/common/linkedinColorIcon.png`;
export const partyFaceIcon = `${NEW_BASE}/common/partyFaceIcon.svg`;
export const partyCapIcon = `${NEW_BASE}/common/partyCapIcon.svg`;
export const rightArrowIcon = `${NEW_BASE}/common/rightArrow.svg`;

// Header
export const headerWhiteLogoIcon = `${NEW_BASE}/common/headerWhiteLogoIcon.svg`;
export const headerGreyLogoIcon = `${NEW_BASE}/common/headerGreyLogoIcon.svg`;

// Footer
export const instagramGreyIcon = `${NEW_BASE}/common/instagramGreyIcon.svg`;
export const facebookGreyIcon = `${NEW_BASE}/common/facebookGreyIcon.svg`;
export const mediumGreyIcon = `${NEW_BASE}/common/mediumGreyIcon.svg`;
export const discordGreyIcon = `${NEW_BASE}/common/discordGreyIcon.svg`;
export const twitterGreyIcon = `${NEW_BASE}/common/twitterGreyIcon.svg`;
export const linkedinGreyIcon = `${NEW_BASE}/common/linkedinGreyIcon.svg`;
export const snapchatGreyIcon = `${NEW_BASE}/common/snapchatGreyIcon.svg`;
export const youtubeGreyIcon = `${NEW_BASE}/common/youtubeGreyIcon.svg`;

// About
export const whatsappLogoIcon = `${NEW_BASE}/pages/about/whatsappLogoIcon.svg`;

// FAQs (TBC - to be moved from S3)
const CDN_URL = "https://cdn.famapp.co.in/prod";
const BASE_ICON_URL = `${CDN_URL}/website`;

export const call = `${BASE_ICON_URL}/icons/call.svg`;
export const chat = `${BASE_ICON_URL}/icons/chat.svg`;
export const backBtn = `${BASE_ICON_URL}/icons/backBtn.svg`;
export const backBtnAlt = `${BASE_ICON_URL}/icons/backBtn-alt.svg`;

export const thumbsDownDark = `/assets/temp/thumbsDownDark.svg`;
export const thumbsUpDark = `/assets/temp/thumbsUpDark.svg`;
export const greenTick = `/assets/temp/greenTick.svg`;

// Lead Insta (TBC - shift with visaLead separation)
export const visaLogo = "/assets/temp/visa.svg";
