// Modules
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Link from "next/link";

// Styles
import styles from "./Footer.module.scss";

// Assets
import {
  headerGreyLogoIcon,
  headerWhiteLogoIcon,
  instagramGreyIcon,
  linkedinGreyIcon,
  twitterGreyIcon,
  youtubeGreyIcon,
} from "../../utils/iconProvider";

const Footer = ({ dark }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const socialHandles = [
    ["Instagram", instagramGreyIcon, "https://www.instagram.com/fam.india/"],
    [
      "LinkedIn",
      linkedinGreyIcon,
      "https://www.linkedin.com/company/famindia/",
    ],
    ["Twitter", twitterGreyIcon, "https://twitter.com/famappbytrio"],
    [
      "Youtube",
      youtubeGreyIcon,
      "https://www.youtube.com/channel/UC58iir7F5tai0Hx28nyGPtA",
    ],
  ];

  return (
    <Container
      fluid
      className={`${styles.footer_container} full_width_section grey_text ${
        dark ? styles.footer_container_dark : ""
      }`}
    >
      <Row>
        <Col lg={5} className={styles.branding_container}>
          <div onClick={scrollToTop}>
            <img
              src={`${dark ? headerWhiteLogoIcon : headerGreyLogoIcon}`}
              className={styles.logo}
              alt={"Fam"}
            />
          </div>
          <p className="subparagraph_text">
            FamApp by Trio (formerly FamPay) focuses on financial inclusion of
            the next generation by providing UPI & card payments. FamApp by
            Trio&apos;s Spending Account, FamX, helps everyone save, spend, earn
            and learn everything about money - all in one app!
          </p>
          <p className={`${styles.footer_copyright} subparagraph_text`}>
            {`© ${new Date().getFullYear()} Fam | All rights reserved`} |{" "}
            <span style={{ display: "inline-block" }}>Made with 🧡</span>
          </p>
        </Col>
        <Col lg={1} />
        <Col lg={6} className={styles.footer_links_container}>
          <Row className={`${styles.footer_links}`}>
            <Col sm={3} className={styles.links_section}>
              <div className={`${styles.heading} paragraph_text`}>Company</div>

              <div
                className={`${styles.footer_link} ${styles.main_link} subparagraph_text `}
              >
                <Link href={"/about"}>About</Link>
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <Link href={"/careers"}>Careers</Link>
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <Link href={"/report-vulnerability"}>Bug bounty</Link>
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <Link href={"/contact"}> Contact Us</Link>
              </div>
            </Col>
            <Col sm={3} className={styles.links_section}>
              <div className={`${styles.heading} paragraph_text`}>
                Resources
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <a href="https://blog.famapp.in/" target="_blank">
                  Blog
                </a>
              </div>
            </Col>
            <Col sm={3} className={styles.links_section}>
              <div className={`${styles.heading} paragraph_text`}>
                FamApp
                <br />
                by Trio
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <Link href={"https://triotech.co.in/privacy"}>Privacy</Link>
              </div>
              <div
                className={`${styles.footer_link} subparagraph_text ${styles.main_link}`}
              >
                <Link href={"https://triotech.co.in/terms"}>Terms</Link>
              </div>
            </Col>
          </Row>
          <Row>
            <div className={styles.social_container}>
              <div className={styles.social_handles}>
                {socialHandles.map((handle, index) => {
                  return (
                    <span className={styles.handle} key={index}>
                      <a href={handle[2]} target={"blank"}>
                        <img
                          className={"social-icon"}
                          src={handle[1]}
                          alt={handle[0]}
                        />
                      </a>
                    </span>
                  );
                })}
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className={styles.footer_links_container}>
          <Row className={`${styles.footer_links}`}>
            <Col sm={12} className={styles.links_section}>
              <div className={`${styles.footer_link} subparagraph_text`}>
                <div className={`${styles.footer_link_text}`}>
                  Address: 3rd Floor, Obeya Verve, L376 / A, 14th B Cross Rd,
                  Sector 6, HSR Layout, Bengaluru, Karnataka 560102
                </div>
                <div className={`${styles.footer_link_text}`}>
                  Email:{" "}
                  <a href="mailto:support@triotech.co.in">
                    support@triotech.co.in
                  </a>
                </div>
                <div className={`${styles.footer_link_text}`}>
                  Phone: <a href="tel:+918045888881">+918045888881</a>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
