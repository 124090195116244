// Modules
import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";

// Components
import PCHeader from "./PCHeader/PCHeader";
import MobHeader from "./MobHeader/MobHeader";
import AnnouncementBanner from "../announcementBanner/AnnouncementBanner";
import useWindowDimensions from "src/utils/useWindowDimensions";

const transparentHeaderPaths = [
  "/",
  "/press",
  "/about",
  "/friends",
  "/careers",
  "/partner",
  "/contact",
];

const checkPath = (routeString) => {
  return transparentHeaderPaths.includes(routeString);
};

const Header = ({ url }) => {
  const router = useRouter();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isHeaderTransparent, setHeaderTransparent] = useState(
    checkPath(router.pathname)
  );
  const [showHeader, setShowHeader] = useState(true);
  const windowHeight = useRef();
  const { width } = useWindowDimensions();
  let isAnnouncement = useRef({
    pcHeader: false,
    mobHeader: false,
  });

  const handleTransparency = () => {
    const currentScroll = document.body.getBoundingClientRect().top;
    if (checkPath(router.pathname)) {
      if (currentScroll < 60) setHeaderTransparent(false);
      else setHeaderTransparent(true);
    }

    /* Disable announcement temprorarily */

    // isAnnouncement.current.mobHeader =
    //   currentScroll === 0 || currentScroll >= windowHeight.current + 56;
    // isAnnouncement.current.pcHeader =
    //   currentScroll === 0 || currentScroll >= windowHeight.current;

    if (
      Math.abs(scrollPosition - currentScroll) > 40 ||
      currentScroll === 0 ||
      currentScroll >= windowHeight.current ||
      currentScroll >= windowHeight.current + 56
    ) {
      setScrollPosition(currentScroll);
      setShowHeader(currentScroll >= scrollPosition);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleTransparency);
    return () => {
      window.removeEventListener("scroll", handleTransparency);
    };
  }, [isHeaderTransparent, scrollPosition]);

  useEffect(() => {
    if (windowHeight.current === undefined) {
      windowHeight.current = window.innerHeight;
    }
    handleTransparency();
  }, [router.pathname]);

  return (
    <>
      {width <= 1200 ? (
        <>
          <AnnouncementBanner
            isMobile={false}
            extraClass={isAnnouncement.current.mobHeader ? undefined : "hidden"}
            headline={
              <span className="primary_orange_text">
                Wohoo! We just launched India's first ever doodle card for
                teens.
              </span>
            }
          />
          <MobHeader
            isAnnouncement={isAnnouncement.current.mobHeader}
            isTransparent={isHeaderTransparent}
            showHeader={showHeader}
            boxShadow={scrollPosition !== 0}
            url={url}
          />
        </>
      ) : (
        <>
          <AnnouncementBanner
            isMobile={false}
            extraClass={isAnnouncement.current.pcHeader ? undefined : "hidden"}
            headline={
              <span className="primary_orange_text">
                Wohoo! We just launched India's first ever doodle card for
                teens.
              </span>
            }
          />
          <PCHeader
            isAnnouncement={isAnnouncement.current.pcHeader}
            isTransparent={isHeaderTransparent}
            showHeader={showHeader}
            boxShadow={scrollPosition !== 0}
            url={url}
          />
        </>
      )}
    </>
  );
};

export default Header;
