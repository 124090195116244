//Import Modules
import React from "react";

//Import Styles
import styles from "./AnnouncementBanner.module.scss";

//Import Assets
import {
  singleGreyArrowIcon,
  partyCapIcon,
  partyFaceIcon,
} from "../../utils/iconProvider";

const AnnouncementBanner = ({ headline, isMobile, extraClass }) => {
  return (
    <a href="https://famcard.me/">
      <div
        className={`${styles.announcement_container} ${styles[extraClass]} ${
          isMobile ? styles.mobile_container : ""
        }`}
      >
        <h6 className="subparagraph_text">
          <img className={styles.emoji} src={partyCapIcon} alt="Party Cap" />
          <img className={styles.emoji} src={partyFaceIcon} alt="Party Face" />
          {headline}
        </h6>
        <img
          style={{
            marginLeft: "8px",
            transform: "rotate(-90deg)",
          }}
          src={singleGreyArrowIcon}
          alt="Arrow Head"
          width={13}
          height={14}
        />
      </div>
    </a>
  );
};

export default AnnouncementBanner;
