// Modules
import React, { useState } from "react";
import { useRouter } from "next/router";
import ActiveLink from "../ActiveLink";

// Styles
import styles from "./MobHeader.module.scss";

// Assets
import {
  headerWhiteLogoIcon,
  headerGreyLogoIcon,
} from "../../../utils/iconProvider";

const MobHeader = ({
  isTransparent,
  boxShadow,
  showHeader,
  isAnnouncement,
  url,
}) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const hideMenu = () => {
    setOpen(false);
  };

  const getCTA = () => {
    switch (router.pathname) {
      case "/about":
        return <a href={"http://join.fampay.in/"}>Join Us</a>;

      case "/careers":
        return <a href={"https://apply.fampay.in"}>View openings</a>;

      default:
        return (
          <a href={url} target="_blank" rel={"noreferrer"}>
            Download
          </a>
        );
    }
  };

  return (
    <div
      className={`${styles.header_outer_container}
       ${isTransparent && !open ? " " : `${styles.white_background} `}
       ${boxShadow && !isTransparent && !open ? `${styles.box_shadow}` : " "}
      `}
      style={{
        overflowY: "hidden",
        top: `${
          showHeader ? (isAnnouncement ? "90px" : "0") : open ? "0" : "-12rem"
        }`,
      }}
    >
      <div className={`${styles.header_container}`}>
        <ActiveLink href={"/"}>
          <a>
            <img
              className={styles.logo}
              alt="FamApp"
              src={
                isTransparent && !open
                  ? headerWhiteLogoIcon
                  : headerGreyLogoIcon
              }
            />
          </a>
        </ActiveLink>
        <div
          onClick={() => setOpen(!open)}
          className={`${open ? styles.header_open_controller : ""} ${
            styles.header_controller
          }`}
        >
          <span
            style={{
              backgroundColor: `${
                isTransparent && !open ? "#ffffff" : "#000000"
              }`,
            }}
          />
          <span
            style={{
              backgroundColor: `${
                isTransparent && !open ? "#ffffff" : "#000000"
              }`,
            }}
          />
          <span
            style={{
              backgroundColor: `${
                isTransparent && !open ? "#ffffff" : "#000000"
              }`,
            }}
          />
        </div>
      </div>

      {open && (
        <div
          className={styles.header_body}
          style={{
            height: `${isAnnouncement ? "calc(100vh - 171px)" : "110vh"}`,
          }}
        >
          <ul className="subparagraph_text">
            <li>
              <ActiveLink
                href={"/about"}
                activeClassName={styles.navlink_active}
                onClick={hideMenu}
              >
                <a>About</a>
              </ActiveLink>
            </li>
            <li>
              <ActiveLink
                href="https://blog.famapp.in/"
                activeClassName={styles.navlink_active}
                target="_blank"
                onClick={hideMenu}
              >
                <a>Blog</a>
              </ActiveLink>
            </li>
            <li>{getCTA()}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobHeader;
