// Modules
import { useRouter } from "next/router";
import React from "react";

// Styles
import styles from "./ButtonContainer.module.scss";

const ButtonContainer = ({
  buttonText,
  wrapStyle,
  url,
  onTap,
  loading,
  activeStatus,
  centered,
  path,
}) => {
  const router = useRouter();
  const handleUrl = (url) => {
    if (!process.browser) {
      return null;
    }
    window.open(url);
  };

  const handleClick = () => {
    if (loading) return null;
    if (path) router.push(`/${path}`);
    else if (onTap) onTap();
    else handleUrl(url);
  };

  return (
    <button
      className={`${styles.button_container} ${
        centered ? styles.centered : ""
      }`}
      onClick={handleClick}
      style={wrapStyle}
      disabled={!activeStatus}
    >
      {loading ? (
        <div
          className="spinner-border spinner-border-sm m-1"
          role="status"
        ></div>
      ) : (
        <div>{buttonText ? buttonText : "Okay"}</div>
      )}
    </button>
  );
};

export default ButtonContainer;
